@font-face {
    font-family: "Dosis" ;
    src: url('../../assets/fonts/Dosis-Light.ttf') format('truetype');
    font-weight: 300;
    
}

@font-face {
    font-family: "Dosis" ;
    src: url('../../assets/fonts/Dosis-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: "Dosis" ;
    src: url('../../assets/fonts/Dosis-SemiBold.ttf') format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: "Dosis" ;
    src: url('../../assets/fonts/Dosis-Bold.ttf') format('truetype');
    font-weight: 700;
}
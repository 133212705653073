.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $margin-mobile;
  @include tablet {
    padding: $margin-tablet;
  }
  @include desktop {
    margin: $margin-desktop;
    width: 102rem;
  }

  &__title {
    font-size: $fontMobile-h1;
    text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.5);


    @include tablet {
      margin: 4rem 0 ;
    }

    @include desktop {
      font-size: $fontDesktop-h1;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin: 1rem 0;
    font-size: 1.5rem;

  }

  input {
    border-radius: 5px;
    border: 1px solid gray;
    padding: 1rem;
    font-family: "Dosis";

    &:focus {
      outline: none;
    }

  }

  textarea {
    @extend input;
    padding: 1rem;
    resize: none;
    height: 20vh;

    @include tablet {
      height: 30vh;
    }
  }
  input[type="submit"] {
    margin-top: 2rem;
    background-color: white;

    &:active {
      background-color: $primary;
      color: white;
    }
  }


  @include desktop {
    margin: $margin-desktop;
    width: 102rem;
  }

  &__getInTouch {
    margin-top: 1rem;
    &--title {
      margin: 0 0 2rem 0;
    }
    &__img {
      margin: 0;
      padding: 2rem;
      width: 7rem;
    }

    &--wrapper {
      display: flex;
      justify-content: center;
    }

    &__copyright {
      display: flex;
      align-items: center;
      justify-content: center;

      &--img {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }

      &--text{
        color: $secondary;
      }
    }
  }
}

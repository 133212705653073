.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $fontMobile-body;
  position: sticky;
  top: 0;
  background-color: $primary;
  padding: 1.6rem 0;
  margin: 0 1.6rem;
  border-bottom: 0.1px solid rgba($color: white, $alpha: 0.2);

  @include tablet {
    margin: $margin-tablet;
    font-size: $fontTablet-body ;
  }
  

  &-container__logo {
    width: 12rem;
    margin: 0;

    @include tablet{
      width: 16rem;
    }

    @include desktop{
      width: 18rem;
    }
  }
  &-nav-container {
    &__list {
      display: flex;
      padding: 0;
    }
    &__link {
      text-decoration: none;
      color: white;
    }
    &__item {
      list-style-type: none;
      margin-right: 0.3rem;
      &:active,
      &:hover {
        text-decoration: underline;
        text-underline-offset: 1rem;
        text-decoration-color: $secondary;
        transition: ease-in 1s;
      }
    }
  }

  &__menu {
    &__list {
      list-style-type: none;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      margin: 0;
    }
    &__item {
      margin-left: 1rem;
    }
    &__link {
      &:hover,
      &:active {
        color: $secondary;
        text-decoration: underline;
        text-underline-offset: 1rem;
      }
    }
  }
}

.intro {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  justify-content: center;
  padding: $margin-mobile;

  @include tablet {
    padding: $margin-tablet;
  }

  @include desktop {
    margin: $margin-desktop;
    width: 102rem;
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__title {
    margin-bottom: 0;
    font-size: 2rem;

    @include desktop {
      font-size: 4rem;
    }
  }
  &__highlight {
    color: $secondary;
    text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.5);
    padding: 0 0.5rem;

  }

  &__sentence {
    font-weight: normal;
    margin-top: 1rem;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
p {
  font-size: $fontMobile-body;

  @include tablet{
    font-size: $fontTablet-body;
  }

}

h1{
  font-size:$fontMobile-h1;

  @include tablet{
    font-size: $fontTablet-h1;
  }

}

h2{
  font-size: 1.8rem;
}
hr{
  height: .1rem;
  color: $secondary;
  margin: $margin-mobile;
  @include tablet{
    margin: $margin-tablet;
  }
}

a{
  color: white;
  text-decoration: none;
}
body {
  font-family: "Dosis", "Franklin Gothic Medium";
  margin: 0;
  background-color: $primary;
  color: white;
  overflow-x: hidden;


}

hr{
  color: white;
}
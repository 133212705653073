.project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $margin-mobile;

  @include tablet {
    padding: $margin-tablet;
  }

  @include desktop {
    margin: $margin-desktop;
    width: 102rem;
  }

  &__title {
    font-size: $fontMobile-h1;
    text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.5);

    @include tablet{
      margin: 4rem 0;
    }

    @include desktop {
      font-size: $fontDesktop-h1;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
  }
  &__subtitle {
    color: $secondary;

    @include desktop {
      margin-left: 7rem;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    border: 1px solid $tertiary;
    border-radius: 5px;
    padding: 2rem;
    background-color: $tertiary;

    @include tablet {
      flex-direction: row;
      align-items: center;
    }

    @include desktop {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    @include tablet {
      width: 40rem;
    }
  }

  &__image--individual {
    width: 100%;
  }

  &__link {
    text-decoration: none;
    color: $secondary;
    font-weight: 700;
  }

  &__description {
    margin-bottom: 3rem;
    @include tablet {
      margin-top: 6rem;
    }
    @include desktop {
      margin-top: 2rem;
    }
  }

  &__tech {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &--icon {
      width: 4rem;
      margin-right: 2rem;
    }
  }

  &__github{
    font-size: 1.6rem;
    text-decoration: underline;
    text-decoration-color:white ;
    text-underline-offset: .5rem;
    color: $secondary;
    text-shadow: 3px 2px 3px rgba(225, 225, 225, 0.1);
    text-transform: uppercase;
    &:hover{
      color: white;
    }


  }
}

.about {
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: center;
  background-color: $primary;
  padding: $margin-mobile;
  @include tablet {
    padding: $margin-tablet;
  }
  @include desktop {
    margin: $margin-desktop;
    width: 102rem;
  }

  &__title {
    font-size: $fontMobile-h1;
    text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.5);

    @include desktop {
      margin: 4rem 0;
      font-size: $fontDesktop-h1;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    @include tablet {
      flex-direction: row;
      align-items: center;
    }
  }

  &-container {
    display: flex;
    justify-content: center;

    &__img{
      width: 30rem;
    }
  }

  &__resume {
    text-decoration: none;
    color: $secondary;
    font-weight: bold;
    text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.5);
    font-size: $fontMobile-body;

    @include tablet {
      display: none;
      margin: 4rem 0;
    }

    &:hover {
      color: white;
    }

    &--tablet {
      display: none;

      @include tablet {
        display: block;
      }
    }
  }

  &__box {
    margin: 1rem;
  }
  &__skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
  }

  &__icons {
    width: 5rem;
    height: 5rem;
  }
}

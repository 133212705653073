$mobile-min: 767px;
$tablet-min: 768px;
$desktop-min: 1280px;

$margin-mobile:0 1.6rem;
$margin-tablet:0 3.6rem;
$margin-desktop: 0 auto;

$fontMobile-body:1.5rem;
$fontMobile-h1:2rem;
$fontTablet-body: 1.6rem;
$fontTablet-h1: 2.2rem;
$fontDesktop-h1: 2.4rem;

$box: 0 0 20px
  rgba(
    $color: black,
    $alpha: 20%,
  );

$mobile-pad: 1.6rem;


$primary:#303841;
$secondary: #00ADB5;
$tertiary: #3A4750;
$quad: #EEEEEE;
